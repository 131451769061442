import { useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as React from "react";

import FilterToolbarDropdown from "~/components/core/FilterToolbar/FilterToolbarDropdown";
import FilterToolbarAdaptiveContent from "~/components/core/FilterToolbarAdaptiveContent";
import styled from "~/components/core/styled";
import { CUSTOM_PERIOD, DATE_FORMAT, PERIOD } from "~/constants/dates";
import { RANGE_GETTERS } from "~/constants/filters";
import { Period, Range } from "~/declarations/filters";

import FilterToolbarTabs from "./FilterToolbarTabs";

interface Props {
  selectedTab?: PERIOD;
  onChange?: (period: Period, range: Range) => void;
  children?: React.ReactNode;
  className?: string;
  periods?: Period[];
  rangeFilter?: Range;
  CustomRangePicker?: React.FC<{
    onSubmit: (range: Range) => void;
    initialRange?: Range;
    hint?: string;
    open: boolean;
    anchorEl: Element | null;
    onClose: () => void;
  }>;
}

export type WithPeriodTitleTooltipProps = {
  period: PERIOD | CUSTOM_PERIOD;
  children: JSX.Element;
};

export const WithPeriodTitleTooltip: React.FC<WithPeriodTitleTooltipProps> = ({
  period,
  children
}: WithPeriodTitleTooltipProps) => {
  let title: React.ReactNode;
  if (period !== PERIOD.custom) {
    const [start, end] = RANGE_GETTERS[period]();
    title = `${start?.format(DATE_FORMAT)} - ${end?.format(DATE_FORMAT)}`;
    if (process.env.NODE_ENV !== "production") {
      title = (
        <>
          {title}
          <br />
          {start?.toISOString()} - {end?.toISOString()}
        </>
      );
    }
  }
  if (!title) {
    return React.cloneElement(children);
  }
  return <StyledTooltip title={title}>{children}</StyledTooltip>;
};

function FilterToolbar(
  {
    onChange,
    selectedTab,
    className,
    CustomRangePicker,
    periods,
    children,
    rangeFilter
  }: Props,
  ref: React.Ref<HTMLDivElement>
): JSX.Element {
  const theme = useTheme();
  const tabletView = useMediaQuery<boolean>(theme.breakpoints.down("md"));
  const mobileView = useMediaQuery<boolean>(theme.breakpoints.down("xs"));
  const [
    customPickerAnchorEl,
    setCustomPickerAnchorEl
  ] = React.useState<HTMLElement | null>(null);

  const handleSelectTab = (periodUnit: Period) => (
    event: React.MouseEvent<HTMLDivElement>
  ): void => {
    event.preventDefault();
    const { period } = periodUnit;
    if (period === PERIOD.custom) {
      setCustomPickerAnchorEl(event.currentTarget);
    } else {
      onChange?.(periodUnit, RANGE_GETTERS[period]());
    }
  };

  const handleCloseCustom = (): void => {
    setCustomPickerAnchorEl(null);
  };

  const handleChangeCustomPeriod = (range: Range): void => {
    setCustomPickerAnchorEl(null);
    onChange?.({ period: PERIOD.custom, title: "Custom" }, range);
  };
  const initialRange: Range =
    rangeFilter && selectedTab === PERIOD.custom ? rangeFilter : [null, null];

  return (
    <FilterToolbarAdaptiveContent className={className} ref={ref}>
      {tabletView && !mobileView ? (
        <FilterToolbarDropdown
          periods={periods}
          selectedTab={selectedTab}
          onSelectTab={handleSelectTab}
        />
      ) : (
        <FilterToolbarTabs
          periods={periods}
          selectedTab={selectedTab}
          onSelectTab={handleSelectTab}
        />
      )}
      {CustomRangePicker && (
        <CustomRangePicker
          open={Boolean(customPickerAnchorEl)}
          onClose={handleCloseCustom}
          anchorEl={customPickerAnchorEl}
          onSubmit={handleChangeCustomPeriod}
          initialRange={initialRange}
        />
      )}

      {children}
    </FilterToolbarAdaptiveContent>
  );
}

export default React.forwardRef(FilterToolbar);

const StyledTooltip = styled(props => (
  <Tooltip
    classes={{ popper: props.className, tooltip: "tooltip" }}
    {...props}
  />
))`
  & .tooltip {
    font-size: 12px;
  }
`;
